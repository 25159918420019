exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aftermovie-index-js": () => import("./../../../src/pages/aftermovie/index.js" /* webpackChunkName: "component---src-pages-aftermovie-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-aviso-index-js": () => import("./../../../src/pages/legal/aviso/index.js" /* webpackChunkName: "component---src-pages-legal-aviso-index-js" */),
  "component---src-pages-legal-cookies-index-js": () => import("./../../../src/pages/legal/cookies/index.js" /* webpackChunkName: "component---src-pages-legal-cookies-index-js" */),
  "component---src-pages-legal-privacidad-index-js": () => import("./../../../src/pages/legal/privacidad/index.js" /* webpackChunkName: "component---src-pages-legal-privacidad-index-js" */),
  "component---src-pages-participa-index-js": () => import("./../../../src/pages/participa/index.js" /* webpackChunkName: "component---src-pages-participa-index-js" */),
  "component---src-pages-vota-index-js": () => import("./../../../src/pages/vota/index.js" /* webpackChunkName: "component---src-pages-vota-index-js" */)
}

